import axios from 'axios';
import dayjs from 'dayjs';
import request from '@/plugins/request';
import store from '@/store';

const { CancelToken } = axios;

export function getBooksets({ showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'GET',
    url: '/bookset/getList',
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      response.data = response.data.map((item) => ({
        ...item,
        label: item.bookset_name,
        value: item.id,
      }));
      store.commit('setBooksets', { booksets: response.data });
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function getBooksetRules({ page = 1, size = 9999, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: '/bookset_rule/index',
    params: {
      page,
      pagesize: size,
    },
    data: {
      page,
      pagesize: size,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      response.data.list = response.data.list.map((rule) => ({
        ...rule,
        booksetId: rule.bookset_id,
        dishesId: rule.dishes_id,
        dishesName: rule.dishes_name,
        newPrice: Number.parseFloat((rule.new_price / 100).toFixed(2)),
        isShow: rule.display_dishes ? '显示' : '隐藏',
        between: `${dayjs.unix(rule.starttime).format('HH:mm:ss')} - ${dayjs
          .unix(rule.endtime)
          .format('HH:mm:ss')}`,
        sort: rule.sort_no === 1 ? '排序' : '不排序',
      }));
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function addBooksetRule({
  booksetId,
  id,
  start,
  end,
  isShow,
  newPrice,
  isSort,
  showError = true,
} = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: '/bookset_rule/add',
    data: {
      bookset_id: booksetId,
      dishes_id: id,
      starttime: start,
      endtime: end,
      display_dishes: isShow,
      new_price: newPrice,
      sort_no: isSort,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  });
  r.cancel = cancel;
  return r;
}

export function removeBooksetRule({ id, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'GET',
    url: '/bookset_rule/delete',
    params: {
      id,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  });
  r.cancel = cancel;
  return r;
}

export function getStoresBooksets({ tabType = 1, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: '/store_type/templates',
    data: {
      type_id: 1,
      menu_type: tabType,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      response.data = response.data.map((item) => ({
        ...item,
        booksetId: item.bookset_id,
        booksetName: item.bookset_name,
        end: item.endtime,
        start: item.starttime,
        typeId: item.type_id,
      }));
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function addStoresBookset({ tabType = 1, booksetId, start, end, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: '/store_type/addtmpl',
    data: {
      type_id: 1,
      menu_type: tabType,
      bookset_id: booksetId,
      starttime: start,
      endtime: end,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  });
  r.cancel = cancel;
  return r;
}

export function setStoresBookset({
  id,
  tabType = 1,
  booksetId,
  start,
  end,
  showError = true,
} = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: '/store_type/edittmpl',
    data: {
      id,
      menu_type: tabType,
      type_id: 1,
      bookset_id: booksetId,
      starttime: start,
      endtime: end,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  });
  r.cancel = cancel;
  return r;
}

export function removeStoresBookset({ id, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'GET',
    url: '/store_type/deletetmpl',
    params: { id },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  });
  r.cancel = cancel;
  return r;
}
