import axios from 'axios';
import request from '@/plugins/request';

const { CancelToken } = axios;

export function getMenu({ showError = true } = {}) {
  let cancel;
  const r = request({
    url: '/dish-libraries/menu',
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => response);
  r.cancel = cancel;
  return r;
}

export function updateDishStatus({ status = 0, dishIds = [], showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: '/dish-libraries/dishes/status',
    data: {
      dish_ids: dishIds,
      status,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => response);
  r.cancel = cancel;
  return r;
}
