import axios from 'axios';
import request from '@/plugins/request';
import store from '@/store';

const { CancelToken } = axios;

export function getStores({ page = 1, size = 9999, keyword, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: '/store/index',
    data: {
      page,
      pagesize: size,
      keyword,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      response.data.list = response.data.list.map((item) => ({
        ...item,
        runningStatus: item.status,
        onlineStatus: item.onset,
        responseStatus: item.online,
        max: item.production_max,
        now: item.production_on,
        lng: item.longitude,
        lat: item.latitude,
        create: item.create_time,
        distanceMode: item.distance_mode,
        nearDistance: item.near_distance,
        farDistance: item.far_distance,
        serviceMode: item.service_mode,
      }));
      store.commit('setStores', { stores: response.data.list });
      return response;
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function addStore({
  storeId,
  name,
  runningStatus,
  onlineStatus,
  type,
  max,
  now,
  lng,
  lat,
  address,
  phones,
  service,
  showError = true,
} = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: '/store/add',
    data: {
      id: storeId,
      name,
      status: runningStatus,
      onset: onlineStatus,
      type,
      production_max: max,
      production_on: now,
      longitude: lng,
      latitude: lat,
      address,
      multiple_phone: phones,
      service,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  });
  r.cancel = cancel;
  return r;
}

export function getStore({ id, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: '/store/get',
    params: { id },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    response.data = {
      ...response.data,
      runningStatus: response.data.status,
      onlineStatus: response.data.onset,
      responseStatus: response.data.online,
      max: response.data.production_max,
      now: response.data.production_on,
      lng: response.data.longitude,
      lat: response.data.latitude,
      create: response.data.create_time,
      adminId: response.data.admin_id,
      phones: response.data.multiple_phone,
    };
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function setStore({
  id,
  name,
  runningStatus,
  onlineStatus,
  type,
  max,
  now,
  lng,
  lat,
  address,
  phones,
  service,
  showError = true,
} = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: '/store/edit',
    data: {
      id,
      name,
      status: runningStatus,
      onset: onlineStatus,
      type,
      production_max: max,
      production_on: now,
      longitude: lng,
      latitude: lat,
      address,
      multiple_phone: phones,
      service,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  });
  r.cancel = cancel;
  return r;
}

export function removeStore({ id, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'GET',
    url: '/store/delete',
    params: { id },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  });
  r.cancel = cancel;
  return r;
}

export function setStoresRunningStatus({ ids, runningStatus, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: '/store/setstatus',
    data: {
      ids,
      status: runningStatus,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  });
  r.cancel = cancel;
  return r;
}

export function setStoresOnlineStatus({ ids, onlineStatus, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: '/store/setonset',
    data: {
      ids,
      onset: onlineStatus,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  });
  r.cancel = cancel;
  return r;
}

export function getStoreStat({ start, end, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'GET',
    url: '/order/summary',
    params: {
      starttime: start,
      endtime: end,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      response.data.list = [
        {
          id: 0,
          name: '汇总',
          ratio: '100%',
          amount: response.data.all_amount,
          total: Number.parseFloat(response.data.all_gross_amount / 100).toFixed(2),
          onlineAmount: response.data.all_amount_out,
          onlineTotal: Number.parseFloat(response.data.all_gross_amount_out / 100).toFixed(2),
          offlineAmount: response.data.all_amount_fetch,
          offlineTotal: Number.parseFloat(response.data.all_gross_amount_fetch / 100).toFixed(2),
        },
        ...response.data.list.map((item) => ({
          ...item,
          ratio: item.per || '0%',
          total: Number.parseFloat(item.gross_amount / 100).toFixed(2),
          onlineTotal: Number.parseFloat(item.gross_amount_out / 100).toFixed(2),
          onlineAmount: item.amount_out,
          offlineTotal: Number.parseFloat(item.gross_amount_fetch / 100).toFixed(2),
          offlineAmount: item.amount_fetch,
        })),
      ];
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function addStoreAdmin({
  username,
  password,
  userType = 'SHOP',
  storeId,
  showError = true,
} = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: '/user/add',
    data: {
      username,
      password,
      user_type: userType,
      store_id: storeId,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  });
  r.cancel = cancel;
  return r;
}

export function setStoreAdmin({ id, password, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: '/user/edit',
    data: {
      id,
      password,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  });
  r.cancel = cancel;
  return r;
}

export function getStoresDelay({ showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: '/delay/get',
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      response.data = {
        ...response.data,
        delay: response.data.time,
      };
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function setStoresDelay({ delay, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: '/delay/set',
    data: {
      time: delay,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  });
  r.cancel = cancel;
  return r;
}

export function getStoresRange({ showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: '/distance/get',
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      response.data = {
        ...response.data,
        nearDistance: response.data.near_distance,
        farDistance: response.data.far_distance,
        maxNum: response.data.max_num_of_stores,
      };
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function setStoresRange({ nearDistance, farDistance, maxNum, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: '/distance/set',
    data: {
      near_distance: nearDistance,
      far_distance: farDistance,
      max_num_of_stores: maxNum,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  });
  r.cancel = cancel;
  return r;
}

export function getStoreRanges({ showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'GET',
    url: '/store-distances',
    params: {
      page: 1,
      pagesize: 9999,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      response.data.list = response.data.list.map((item) => ({
        ...item,
        id: item.store_id,
        storeId: item.store_id,
        nearDistance: item.near_distance,
        farDistance: item.far_distance,
      }));
      store.commit('setRanges', { ranges: response.data.list });
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function setStoreRange({ id, nearDistance, farDistance, showError = true } = {}) {
  let cancel;
  const ranges = [...store.state.ranges];
  const index = ranges.findIndex((range) => range.id === id);
  const r = request({
    method: 'POST',
    url: index >= 0 ? `/store-distance/${ranges[index].id}/update` : '/store-distance',
    data: {
      store_id: id,
      near_distance: nearDistance,
      far_distance: farDistance,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      store.commit('setRanges', {
        ranges: [
          ...ranges.slice(0, index),
          {
            id,
            nearDistance: Number.parseInt(nearDistance, 10),
            farDistance: Number.parseInt(farDistance, 10),
          },
          ...ranges.slice(index + 1),
        ],
      });
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function removeStoreRange({ ids, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: '/store-distance/delete',
    data: {
      store_ids: ids,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      store.commit('setRanges', {
        ranges: [...store.state.ranges].filter((range) => !ids.includes(range.id)),
      });
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function getStoresRules({ page = 1, size = 9999, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: '/order_rule/index',
    data: {
      page,
      pagesize: size,
    },
    params: {
      page,
      pagesize: size,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      response.data.list = response.data.list.map((item) => ({
        ...item,
        outStoreId: item.bef_store,
        outStoreName: item.bef_store_name,
        inStoreId: item.aft_store,
        inStoreName: item.aft_store_name,
      }));
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function setStoresRule({ outStoreId, inStoreId, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: '/order_rule/add',
    data: {
      bef_store: outStoreId,
      aft_store: inStoreId,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  });
  r.cancel = cancel;
  return r;
}

export function removeStoresRule({ id, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'GET',
    url: '/order_rule/delete',
    params: {
      id,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  });
  r.cancel = cancel;
  return r;
}

export function getStoreGroups({ page = 1, size = 9999, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'GET',
    url: '/store-rotations',
    params: {
      page,
      pagesize: size,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      response.data.list = response.data.list.map((item) => ({
        ...item,
        storeIds: item.store_ids,
        text: item.address,
      }));
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function setStoreGroup({ id, storeIds, text, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: id ? `/store-rotation/${id}/edit` : '/store-rotation',
    data: {
      store_ids: storeIds,
      address: text,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  });
  r.cancel = cancel;
  return r;
}

export function removeStoreGroups({ ids, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: `/store-rotation/delete?ids=${ids.join(',')}`,
    data: {
      ids,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  });
  r.cancel = cancel;
  return r;
}

export function getStoresLogs({ id, page = 1, size = 10, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'GET',
    url: `/stores/logs`,
    params: {
      store_id: id,
      page,
      pagesize: size,
    },
    showError,
  });
  r.cancel = cancel;
  return r;
}
