export default [
  {
    name: '404',
    path: '/404',
    component: () => import('@/views/exception/404.vue'),
  },
  {
    name: 'login',
    path: '/',
    alias: '/login',
    component: () => import('@/views/login/index.vue'),
  },
  // 控制台
  {
    path: '/dashboard',
    component: () => import('@/layout/index.vue'),
    children: [
      {
        path: '',
        name: 'dashboard',
        component: () => import('@/views/dashboard/index.vue'),
        meta: {
          keepAlive: true,
        },
      },
    ],
  },
  // 分店管理
  {
    path: '/store',
    name: 'store',
    redirect: { name: 'store-list' },
    component: () => import('@/layout/index.vue'),
    children: [
      {
        path: 'add',
        name: 'store-add',
        component: () => import('@/views/store/add.vue'),
      },
      {
        path: 'list',
        name: 'store-list',
        component: () => import('@/views/store/list.vue'),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: 'setting',
        name: 'store-setting',
        component: () => import('@/views/store/setting.vue'),
      },
      {
        path: 'logs',
        name: 'store-logs',
        component: () => import('@/views/store/logs.vue'),
      },
      {
        path: 'rule',
        name: 'store-rule',
        component: () => import('@/views/store/rule/index.vue'),
      },
    ],
  },
  // 当天报表
  {
    path: '/today',
    component: () => import('@/layout/index.vue'),
    children: [
      {
        path: '',
        name: 'today',
        component: () => import('@/views/report/index.vue'),
      },
    ],
  },
  // 历史报表
  {
    path: '/history',
    component: () => import('@/layout/index.vue'),
    children: [
      {
        path: '',
        name: 'history',
        component: () => import('@/views/report/index.vue'),
      },
    ],
  },
  // 订单详情
  {
    path: '/detail',
    component: () => import('@/layout/index.vue'),
    children: [
      {
        path: '',
        name: 'detail',
        component: () => import('@/views/detail/index.vue'),
      },
    ],
  },
  // 外卖模板设置
  {
    path: '/online',
    component: () => import('@/layout/index.vue'),
    children: [
      {
        path: '',
        name: 'online',
        component: () => import('@/views/template/index.vue'),
      },
    ],
  },
  // 自提模板设置
  {
    path: '/offline',
    component: () => import('@/layout/index.vue'),
    children: [
      {
        path: '',
        name: 'offline',
        component: () => import('@/views/template/index.vue'),
      },
    ],
  },
  // 标签页设置
  {
    path: '/tab',
    component: () => import('@/layout/index.vue'),
    children: [
      {
        path: '',
        name: 'tab',
        component: () => import('@/views/tab/index.vue'),
      },
    ],
  },
  // 派餐地址库
  {
    path: '/address',
    component: () => import('@/layout/index.vue'),
    children: [
      {
        path: '',
        name: 'address',
        component: () => import('@/views/lib/index.vue'),
      },
    ],
  },
  {
    path: '/negative-address',
    component: () => import('@/layout/index.vue'),
    children: [
      {
        path: '',
        name: 'negative-address',
        component: () => import('@/views/lib/index.vue'),
      },
    ],
  },
  // 派餐区域库
  {
    path: '/region',
    component: () => import('@/layout/index.vue'),
    children: [
      {
        path: '',
        name: 'region',
        component: () => import('@/views/lib/index.vue'),
      },
    ],
  },
  // 菜式库
  {
    path: '/dish-library',
    component: () => import('@/layout/index.vue'),
    children: [
      {
        path: '',
        name: 'dish-library',
        component: () => import('@/views/dish-library/index.vue'),
      },
    ],
  },
  // 送餐分店轮换
  {
    path: '/group',
    component: () => import('@/layout/index.vue'),
    children: [
      {
        path: '',
        name: 'group',
        component: () => import('@/views/group/index.vue'),
      },
    ],
  },
  // 派单距离模板
  {
    path: '/range-template',
    component: () => import('@/layout/index.vue'),
    children: [
      {
        path: '',
        name: 'RangeTemplate',
        component: () => import('@/views/auto/index.vue'),
      },
      {
        path: 'edit',
        name: 'RangeTemplate-edit',
        component: () => import('@/views/auto/edit.vue'),
      },
    ],
  },
  // 自动上下线模板
  {
    path: '/online-template',
    component: () => import('@/layout/index.vue'),
    children: [
      {
        path: '',
        name: 'OnlineStatusTemplate',
        component: () => import('@/views/auto/index.vue'),
      },
      {
        path: 'edit',
        name: 'OnlineStatusTemplate-edit',
        component: () => import('@/views/auto/edit.vue'),
      },
    ],
  },
  // 定时修改服务模板
  {
    path: '/service-template',
    component: () => import('@/layout/index.vue'),
    children: [
      {
        path: '',
        name: 'ServiceTemplate',
        component: () => import('@/views/auto/index.vue'),
      },
      {
        path: 'edit',
        name: 'ServiceTemplate-edit',
        component: () => import('@/views/auto/edit.vue'),
      },
    ],
  },
  // 轮播图
  {
    path: '/carousel',
    component: () => import('@/layout/index.vue'),
    children: [
      {
        path: '',
        name: 'Carousel',
        component: () => import('@/views/carousel/index.vue'),
        meta: {
          keepAlive: true,
        },
      },
      {
        path: 'edit',
        name: 'Carousel-edit',
        component: () => import('@/views/carousel/edit.vue'),
      },
    ],
  },
  // 单店模式
  {
    path: '/single',
    component: () => import('@/layout/index.vue'),
    children: [
      {
        path: '',
        name: 'Single',
        component: () => import('@/views/single/index.vue'),
        meta: {
          keepAlive: true,
        },
      },
    ],
  },
  // 明厨亮灶
  {
    path: '/live',
    component: () => import('@/layout/index.vue'),
    children: [
      {
        path: '',
        name: 'Live',
        component: () => import('@/views/live/index.vue'),
        meta: {
          keepAlive: true,
        },
      },
    ],
  },
  // 不匹配一律 404
  {
    path: '*',
    redirect: { name: '404' },
  },
];
