import { Loading } from 'element-ui';

export const showLoading = ({
  target = 'document.body',
  body = false,
  fullscreen = true,
  lock = true,
  text = '请求中，请稍候',
  spinner = 'el-icon-loading',
  background = 'rgba(0, 0, 0, 0.7)',
} = {}) => {
  const instance = Loading.service({
    target,
    body,
    fullscreen,
    lock,
    text,
    spinner,
    background,
  });
  return () => {
    instance.close();
  };
};
