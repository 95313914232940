import { MessageBox } from 'element-ui';

export const showAlert = ({
  code,
  message = '未知错误',
  title = '错误',
  type = 'error',
  thenFunc = () => {},
  catchFunc = () => {},
  finallyFunc = () => {},
} = {}) => {
  const content = code ? `错误码：${code}，错误信息：${message}。` : `${message}`;
  MessageBox.alert(content, {
    title,
    type,
  })
    .then(thenFunc)
    .catch(catchFunc)
    .finally(finallyFunc);
};
