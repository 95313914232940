import axios from 'axios';
import request from '@/plugins/request';
import store from '@/store';

const { CancelToken } = axios;

export function signIn({ username, password, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: '/public/login',
    data: {
      username,
      password,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      const { token, id, user_type: type, username: name, store_id: storeId } = response.data;
      store.commit('setUser', { user: { token, id, type, name, storeId } });
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function renew({ showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: '/public/renew',
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      const { token, id, user_type: type, username: name, store_id: storeId } = response.data;
      store.commit('setUser', { user: { token, id, type, name, storeId } });
    } else {
      store.commit('reset');
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}
