import Vue from 'vue';
import axios from 'axios';
import axiosRetry from 'axios-retry';
import { getToken, clearStorage, showAlert } from '@/utils';
import { baseUrl, timeout, headers } from '@/data';

/** @desc 需要返回到首页并清空登录信息的响应代码 */
export const reLaunchCodes = new Set([
  'TOKEN_OVERTIME',
  'LOGIN_FAILURE',
  '10011',
  '10021',
  10_011,
  10_021,
]);

/** @desc 状态码对应的国际化键 */
export const objectStatusCode = {
  400: '请求语法错误',
  401: '未认证',
  403: '拒绝访问',
  404: '请求的资源未被找到',
  405: '请求方法被禁止',
  406: '请求没有被接受',
  407: '代理未认证',
  408: '请求超时',
  409: '处理请求时发生冲突',
  410: '请求的资源已被移除',
  411: '请求缺少 Content-Length',
  412: '请求前置条件头错误',
  413: '请求实体过大',
  414: '请求 URI 过长',
  415: '请求的媒体格式无效',
  416: '请求头部 Range 无效',
  417: '请求头部 Expect 无效',
  421: '请求的服务器无法响应',
  422: '请求实体语义错误',
  423: '请求的资源被锁定',
  424: '请求失败',
  426: '请求协议需要升级',
  428: '请求缺少前置条件头',
  429: '请求过多',
  431: '请求头字段过大',
  451: '因法律政策原因无法处理请求',
  500: '服务器内部错误',
  501: '请求方法未支持',
  502: '网关错误',
  503: '服务不可用',
  504: '网关超时',
  505: 'HTTP 协议版本无效',
  506: '服务器内部配置错误',
  507: '服务器空间不足',
  508: '存在死循环',
  510: '需要进一步扩展',
  511: '网络未认证',
};

/** @param {number} statusCode */
const handleValidateStatusCode = (statusCode) =>
  (statusCode >= 200 && statusCode < 300) || statusCode === 304;

/** @desc 请求实例 */
const instance = axios.create({
  baseURL: baseUrl,
  timeout,
  headers,
  withCredentials: false,
  responseType: 'json',
  validateStatus: handleValidateStatusCode,
});

instance.interceptors.request.use((config) => ({
  ...config,
  headers: {
    ...config.headers,
    'X-Token': getToken() || '',
  },
}));
axiosRetry(axios, { retryDelay: axiosRetry.exponentialDelay });

instance.interceptors.response.use(
  (response) => {
    response.data = {
      ...response.data,
      success: response.data.code === 200 || !response.data.code,
      message: response.data.msg || '',
      code: response.data.code || 200,
      data: response.data.data || response.data,
    };
    if (reLaunchCodes.has(response.data.code)) {
      clearStorage();
      window.location.reload();
    } else if (!response.data.success && response.config.showError !== false) {
      showAlert(response.data);
    }
    return response.data;
  },
  (error) => {
    if (axios.isCancel(error)) {
      // 取消请求
      return {
        success: false,
        message: '请求已取消',
        code: 'REQUEST_CANCELLED',
      };
    }
    const response = {
      success: false,
      message: '',
      code: '',
    };
    if (error.response) {
      // 发送了请求且有响应
      let { status } = error.response;
      if (!handleValidateStatusCode(status)) {
        // 状态码不正常
        status = JSON.stringify(status);
        response.code = status;
        response.message = objectStatusCode[status] || '发生了错误';
      } else {
        // 超时
        const timeoutCodes = ['TIMEOUT', 'CONNRESET'];
        const strError = JSON.stringify(error).toUpperCase();
        for (let i = 0, len = timeoutCodes.length; i < len; i += 1) {
          if (strError.includes(timeoutCodes[i])) {
            response.code = 'REQUEST_TIMEOUT';
            response.message = '请求超时';
            break;
          }
        }
      }
    } else if (error.request) {
      // 发送了请求，没有收到响应
      response.code = 'NO_RESPONSE';
      response.message = '服务器无响应';
    } else {
      // 请求时发生错误
      response.code = 'REQUEST_ERROR';
      response.message = '请求错误';
    }
    if (reLaunchCodes.has(response.code)) {
      clearStorage();
      window.location.reload();
    } else if (error.config.showError !== false) {
      showAlert(response);
    }
    return response;
  },
);

Vue.prototype.$request = instance;

export default instance;
