import Vue from 'vue';
import Router from 'vue-router';
import NProgress from 'nprogress';
import { Notification } from 'element-ui';
import {
  renew,
  getBooksets,
  getStores,
  getStoreRanges,
  getOnlineStatusTemplates,
  getRangeTemplates,
  getServiceTemplates,
  getPickups,
  getCarousels,
  getLiveUrls,
} from '@/apis';
import store from '../store';
import routes from './routes';

Vue.use(Router);

// const commonRoutes = ['/', '/login'];
// const exceptionRoutes = ['/404'];

// const loading = null;

const router = new Router({
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

// 导航守卫
router.beforeEach((to, from, next) => {
  NProgress.start();
  const { token, id } = store.state.user;

  // 未登录
  if (!token) {
    if (to.name === 'login') {
      // 原本前往登录页，允许前往
      next();
      return;
    }
    // 原本前往非登录页，前往登录页，并记录原本想前往的页面，登录后自动跳转
    Notification.warning({
      title: '提示',
      message: '请先登录。',
    });
    next({
      name: 'login',
      query: {
        redirect: to.name,
      },
    });
    return;
  }

  // 已登录，还没获取到用户信息 => 更新 token，获取相关信息
  if (id === -1) {
    renew({ showError: false }).then((response) => {
      if (response.success) {
        // 更新 token 成功，进一步获取相关信息
        const tasks = [getStores({ showError: false })];
        if (store.state.user.type !== 5) {
          tasks.push(
            getBooksets({ showError: false }),
            getStoreRanges({ showError: false }),
            getOnlineStatusTemplates({ showError: false }),
            getRangeTemplates({ showError: false }),
            getServiceTemplates({ showError: false }),
            getPickups({ showError: false }),
            getCarousels({ showError: false }),
            getLiveUrls({ showError: false }),
          );
        }
        Promise.all(tasks).then((responses) => {
          const r = responses.find((item) => !item.success);
          if (r) {
            // 获取相关信息失败，重新登录
            store.commit('reset');
            Notification.warning({
              title: '提示',
              message: '请重新登录。',
            });
            next({
              name: 'login',
              query: {
                redirect: to.name,
              },
              replace: true,
            });
          } else if (to.name === 'login') {
            // 获取相关信息成功，自动跳转
            next({
              name: store.state.user.type !== 5 ? 'dashboard' : 'today',
              replace: true,
            });
          } else {
            next({ ...to, replace: true });
          }
        });
      } else {
        // 更新 token 失败，重新登录
        Notification.warning({
          title: '提示',
          message: '请重新登录。',
        });
        next({
          name: 'login',
          query: {
            redirect: to.name,
          },
          replace: true,
        });
      }
    });
    return;
  }

  // 已登录，已获取到用户信息 => 允许跳转
  next();
});

router.afterEach(() => {
  // loading.close();
  NProgress.done();
});

export default router;
