import Vue from 'vue';
import '@/plugins';
import browserUpdate from 'browser-update';
// import * as Sentry from '@sentry/vue';
// import { BrowserTracing } from '@sentry/tracing';
import router from './router';
import store from './store';
import App from './App.vue';
import '@/styles/preflight.scss';
import '@/styles/global.scss';
import '@/styles/tailwind.scss';

Vue.config.productionTip = false;

// Sentry.init({
//   Vue,
//   dsn: 'https://3b461c4c6d7a414382170d7436cb7c69@o1234311.ingest.sentry.io/6527153',
//   integrations: [
//     new BrowserTracing({
//       routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//       tracingOrigins: ['localhost', 'dcweb.wantease.cn', 'duchenggo.net', /^\//],
//     }),
//   ],
//   tracesSampleRate: 0.2,
// });

browserUpdate({
  required: { e: 79, f: 67, o: 50, s: 12, c: 63 },
  insecure: true,
  unsupported: true,
  // onshow: () => {
  //   Sentry.captureEvent({
  //     message: 'Outdated Browser',
  //   });
  // },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
