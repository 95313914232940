// import store from '@/store';
import axios from 'axios';
import dayjs from 'dayjs';
import { camelCase } from 'change-case';
import request from '@/plugins/request';

const { CancelToken } = axios;

export function getOrders({
  storeId,
  start,
  end,
  status,
  payment,
  phone,
  page,
  size,
  showError = true,
} = {}) {
  let cancel;
  const r = request({
    method: 'GET',
    url: '/order/index',
    params: {
      store_id: storeId,
      starttime: start,
      endtime: end,
      status,
      payment,
      phone,
      page,
      pagesize: size,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      response.data.list = response.data.list.map((item) => ({
        ...item,
        id: item.order_num,
        address: `${item.address} ${item.addressno}`,
        totalPrice: Number.parseFloat(item.total_price / 100).toFixed(2),
        orderTime: dayjs.unix(item.ordertime).format('YYYY-MM-DD HH:mm:ss'),
        storeId: item.store_id,
        storeName: item.store_name,
        isGroupBuy: item.is_group_buy,
      }));
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function getOrdersReport({
  storeId,
  start,
  end,
  payment,
  status,
  type = 0,
  showError = true,
} = {}) {
  let cancel;
  const r = request({
    method: 'GET',
    url: '/order/reports',
    params: {
      store_id: storeId,
      starttime: start,
      endtime: end,
      payment,
      status,
      order_type: type,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      const typeMap = {
        total: 'total',
        delivery: 'online',
        fetch: 'offline',
      };
      response.data = Object.fromEntries(
        Object.keys(response.data).map((curType) => [
          typeMap[curType] || curType,
          Object.fromEntries(
            Object.keys(response.data[curType]).map((curPayment) => [
              camelCase(curPayment),
              Object.fromEntries(
                Object.keys(response.data[curType][curPayment]).map((curField) => [
                  camelCase(curField),
                  response.data[curType][curPayment][curField],
                ]),
              ),
            ]),
          ),
        ]),
      );
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function getOrderDetail({ orderId, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'GET',
    url: '/order',
    params: { order_num: orderId },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      response.data = {
        ...response.data,
        orderId: response.data.order_num,
        originalPrice: response.data.original_price,
        totalPrice: Number.parseFloat(response.data.total_price / 100).toFixed(2),
        address: `${response.data.address} ${response.data.addressno}`,
        storeId: response.data.store_id,
        storeName: response.data.store_name,
        storeAddress: response.data.store_address,
        orderTime: dayjs.unix(response.data.ordertime).format('YYYY-MM-DD HH:mm:ss'),
        expiredTime: dayjs.unix(response.data.expire_time).format('YYYY-MM-DD HH:mm:ss'),
        code: response.data.fetch_code,
        orderType: response.data.order_type,
        ecard:
          response.data.e_card.company_name && response.data.e_card.nickname
            ? `团餐客户：${response.data.e_card.company_name}，会员：${
                response.data.e_card.nickname
              } (${response.data.e_card.username})，单号：${
                response.data.e_card.order_list.join('、') || '无'
              }`
            : '',
        rider:
          response.data.rider_name && response.data.rider_phone
            ? `${response.data.rider_name} ${response.data.rider_phone}`
            : '',
        isGroupBuy: response.data.is_group_buy,
        dishes: response.data.dishes.flatMap((item) =>
          item.dishes.map((item_, index) => ({
            username: `${item.user.name}(${item.user.is_initiator ? '发起人' : '受邀人'})`,
            remark: item.remark,
            rowspan: index === 0 ? item.dishes.length : 0,
            colspan: index === 0 ? 1 : 0,
            id: item_.dishes_id,
            name:
              item_.dishes_name +
              ((item_.requirement ?? []).length === 0
                ? ''
                : `，${item_.requirement.map((item__) => item__.dishes_name).join('、')}`),
            count: item_.amount,
            totalPrice: Number.parseFloat(item_.total_price / 100).toFixed(2),
          })),
        ),
      };
      if (response.data.orderType === 1) {
        response.data.expectedTime = response.data.delivery_time || '立即送出';
      } else {
        response.data.expectedTime = response.data.delivery_time
          ? `${response.data.delivery_time.slice(-2)} 分钟后`
          : '立即取餐';
      }
      const contents = [];
      const types = {
        0: '待支付',
        1: '餐到付款',
        2: '电子卡',
        3: '电子卡 + 餐到付款',
        4: '小程序微信',
        6: '电子卡 + 小程序微信',
        8: '电子券',
        9: '电子券 + 餐到付款',
        10: '电子券 + 电子卡',
        12: '电子券 + 小程序微信',
        16: '小程序支付宝',
        18: '电子卡 + 小程序支付宝',
        24: '电子券 + 小程序支付宝',
        32: '应用内微信',
        34: '电子卡 + 应用内微信',
        40: '电子券 + 应用内微信',
        64: '应用内支付宝',
        66: '电子卡 + 应用内支付宝',
        72: '电子券 + 应用内支付宝',
      };
      switch (response.data.payment) {
        case 0:
          response.data.payment = '待支付';
          break;
        case 1:
          response.data.payment = `餐到付款 ${(response.data.payment_info[0].amount / 100).toFixed(
            2,
          )}`;
          break;
        case 2:
          response.data.payment = `电子卡 ${(response.data.payment_info[0].amount / 100).toFixed(
            2,
          )}`;
          break;
        case 3:
          for (const type of [2, 1]) {
            const detail = response.data.payment_info.find((item) => item.type === type);
            if (detail && detail.amount) {
              contents.push(`${types[type]} ${(detail.amount / 100).toFixed(2)}`);
            }
          }
          response.data.payment = contents.join('，');
          break;
        case 4:
          response.data.payment = `小程序微信 ${(
            response.data.payment_info[0].amount / 100
          ).toFixed(2)}`;
          break;
        case 6:
          for (const type of [2, 4]) {
            const detail = response.data.payment_info.find((item) => item.type === type);
            if (detail && detail.amount) {
              contents.push(`${types[type]} ${(detail.amount / 100).toFixed(2)}`);
            }
          }
          response.data.payment = contents.join('，');
          break;
        case 8:
          response.data.payment = `电子券 ${(response.data.payment_info[0].amount / 100).toFixed(
            2,
          )}`;
          break;
        case 9:
          for (const type of [8, 1]) {
            const detail = response.data.payment_info.find((item) => item.type === type);
            if (detail && detail.amount) {
              contents.push(`${types[type]} ${(detail.amount / 100).toFixed(2)}`);
            }
          }
          response.data.payment = contents.join('，');
          break;
        case 10:
          for (const type of [8, 2]) {
            const detail = response.data.payment_info.find((item) => item.type === type);
            if (detail && detail.amount) {
              contents.push(`${types[type]} ${(detail.amount / 100).toFixed(2)}`);
            }
          }
          response.data.payment = contents.join('，');
          break;
        case 12:
          for (const type of [8, 4]) {
            const detail = response.data.payment_info.find((item) => item.type === type);
            if (detail && detail.amount) {
              contents.push(`${types[type]} ${(detail.amount / 100).toFixed(2)}`);
            }
          }
          response.data.payment = contents.join('，');
          break;
        case 16:
          response.data.payment = `小程序支付宝 ${(
            response.data.payment_info[0].amount / 100
          ).toFixed(2)}`;
          break;
        case 18:
          for (const type of [2, 16]) {
            const detail = response.data.payment_info.find((item) => item.type === type);
            if (detail && detail.amount) {
              contents.push(`${types[type]} ${(detail.amount / 100).toFixed(2)}`);
            }
          }
          response.data.payment = contents.join('，');
          break;
        case 24:
          for (const type of [8, 16]) {
            const detail = response.data.payment_info.find((item) => item.type === type);
            if (detail && detail.amount) {
              contents.push(`${types[type]} ${(detail.amount / 100).toFixed(2)}`);
            }
          }
          response.data.payment = contents.join('，');
          break;
        case 32:
          response.data.payment = `应用内微信 ${(
            response.data.payment_info[0].amount / 100
          ).toFixed(2)}`;
          break;
        case 34:
          for (const type of [2, 32]) {
            const detail = response.data.payment_info.find((item) => item.type === type);
            if (detail && detail.amount) {
              contents.push(`${types[type]} ${(detail.amount / 100).toFixed(2)}`);
            }
          }
          response.data.payment = contents.join('，');
          break;
        case 40:
          for (const type of [8, 32]) {
            const detail = response.data.payment_info.find((item) => item.type === type);
            if (detail && detail.amount) {
              contents.push(`${types[type]} ${(detail.amount / 100).toFixed(2)}`);
            }
          }
          response.data.payment = contents.join('，');
          break;
        case 64:
          response.data.payment = `应用内支付宝 ${(
            response.data.payment_info.find((item) => item.type === 64).amount / 100
          ).toFixed(2)}`;
          break;
        case 66:
          for (const type of [2, 64]) {
            const detail = response.data.payment_info.find((item) => item.type === type);
            if (detail && detail.amount) {
              contents.push(`${types[type]} ${(detail.amount / 100).toFixed(2)}`);
            }
          }
          response.data.payment = contents.join('，');
          break;
        case 72:
          for (const type of [8, 64]) {
            const detail = response.data.payment_info.find((item) => item.type === type);
            if (detail && detail.amount) {
              contents.push(`${types[type]} ${(detail.amount / 100).toFixed(2)}`);
            }
          }
          response.data.payment = contents.join('，');
          break;
        default:
          response.data.payment = '不明支付方式，请联系维护并提供当前页面网址';
          break;
      }
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function getOrderDispatchLogs({ orderId, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'GET',
    url: '/order/log',
    params: { order_num: orderId },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  });
  r.cancel = cancel;
  return r;
}

export function getOrderUpdateLogs({ orderId, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'GET',
    url: '/order/logs',
    params: { order_num: orderId },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  });
  r.cancel = cancel;
  return r;
}

export function setOrder({ orderId, storeId, status, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: '/order/edit',
    params: {
      order_num: orderId,
      store_id: storeId,
      status,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  });
  r.cancel = cancel;
  return r;
}

export function removeOrder({ orderId, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: '/order/delete',
    params: {
      order_num: orderId,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  });
  r.cancel = cancel;
  return r;
}

export function getOrdersReportExcel({
  showError = true,
  start,
  end,
  storeId,
  payment,
  status,
} = {}) {
  let cancel;
  const r = request({
    method: 'GET',
    url: encodeURI(
      `/order/report-export?store_id=${storeId}&starttime=${start}&endtime=${end}&payment=${payment}&status=${status}`,
    ),
    responseType: 'blob',
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  });
  r.cancel = cancel;
  return r;
}

export function getOrder({ showError = true, id } = {}) {
  let cancel;
  const r = request({
    method: 'GET',
    url: `/order/by-various-order?order_id=${id}`,
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  });
  r.cancel = cancel;
  return r;
}
