import axios from 'axios';
import dayjs from 'dayjs';
import request from '@/plugins/request';
import store from '@/store';
import { getStores } from './store';

const { CancelToken } = axios;

export function getTabTemplates({ showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'GET',
    url: '/menu-rules',
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      response.data = response.data.map((item) => ({
        ...item,
        tabType: item.menu_type,
        start: item.starttime,
        end: item.endtime,
      }));
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function setTabTemplate({ id, tabType, start, end, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: id ? '/menu-rule/edit' : '/menu-rule',
    data: {
      id,
      menu_type: tabType,
      starttime: start,
      endtime: end,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  });
  r.cancel = cancel;
  return r;
}

export function removeTabTemplates({ ids, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: '/menu-rule/delete',
    data: {
      ids,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  });
  r.cancel = cancel;
  return r;
}

export function getOnlineStatusTemplates({ showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'GET',
    url: '/store-status-templates',
    params: {
      page: 1,
      pagesize: 9999,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      response.data.templates = response.data.templates.map((item) => ({
        ...item,
        tasks: item.tasks
          .map((task) => ({
            ...task,
            onlineStatus: task.status,
          }))
          .sort(
            (taskA, taskB) =>
              Number.parseInt(dayjs(taskA.time, 'HH:mm').format('HHmm'), 10) -
              Number.parseInt(dayjs(taskB.time, 'HH:mm').format('HHmm'), 10),
          ),
        stores: [...item.applied_stores],
      }));
      store.commit('setOnlineStatusTemplates', {
        onlineStatusTemplates: response.data.templates,
      });
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function setOnlineStatusTemplate({ id, name, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: id ? `/store-status-template/${id}/edit` : '/store-status-template',
    data: {
      id,
      name,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  });
  r.cancel = cancel;
  return r;
}

export function removeOnlineStatusTemplates({ ids, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: '/store-status-template/delete',
    data: {
      ids,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      getStores();
      getOnlineStatusTemplates();
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function setOnlineStatusTemplateTask({ id, time, onlineStatus, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: `/store-status-template/${id}/task`,
    data: {
      time,
      status: onlineStatus,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      getStores();
      getOnlineStatusTemplates();
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function removeOnlineStatusTemplateTasks({ id, taskIds, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: `/store-status-template/${id}/task/delete`,
    data: {
      task_ids: taskIds,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      getStores();
      getOnlineStatusTemplates();
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function setOnlineStatusTemplateStores({ id, storeIds, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: `/store-status-template/${id}/store/apply-template`,
    data: {
      store_ids: storeIds,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      getStores();
      getOnlineStatusTemplates();
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function setOnlineStatusTemplateStore({ id, storeId, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: `/store-status-template/${id}/store/${storeId}/template`,
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      getStores();
      getOnlineStatusTemplates();
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function removeOnlineStatusTemplateStore({ storeIds, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: `/store-status-template/store/cancel-template`,
    data: {
      store_ids: storeIds,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    // PERF
    if (response.success) {
      getStores();
      getOnlineStatusTemplates();
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function getRangeTemplates({ showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'GET',
    url: '/store-distance-templates',
    params: {
      page: 1,
      pagesize: 9999,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      response.data.templates = response.data.templates.map((item) => ({
        ...item,
        tasks: item.tasks
          .map((task) => ({
            ...task,
            start: task.start_time,
            end: task.end_time,
            time: [task.start_time, task.end_time],
            nearDistance: task.near_distance,
            farDistance: task.far_distance,
          }))
          .sort(
            (taskA, taskB) =>
              Number.parseInt(dayjs(taskA.start, 'HH:mm').format('HHmm'), 10) -
              Number.parseInt(dayjs(taskB.start, 'HH:mm').format('HHmm'), 10),
          ),
        stores: [...item.applied_stores],
      }));
      store.commit('setRangeTemplates', {
        rangeTemplates: response.data.templates,
      });
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function setRangeTemplate({ id, name, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: id ? `/store-distance-template/${id}/edit` : '/store-distance-template',
    data: {
      id,
      name,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  });
  r.cancel = cancel;
  return r;
}

export function removeRangeTemplates({ ids, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: '/store-distance-template/delete',
    data: {
      ids,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      getStores();
      getRangeTemplates();
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function setRangeTemplateTask({
  id,
  start,
  end,
  time,
  nearDistance,
  farDistance,
  showError = true,
} = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: `/store-distance-template/${id}/task`,
    data: {
      start_time: time[0] || start,
      end_time: time[1] || end,
      near_distance: nearDistance,
      far_distance: farDistance,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      getStores();
      getRangeTemplates();
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function removeRangeTemplateTasks({ id, taskIds, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: `/store-distance-template/${id}/task/delete`,
    data: {
      task_ids: taskIds,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      getStores();
      getRangeTemplates();
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function setRangeTemplateStores({ id, storeIds, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: `/store-distance-template/${id}/store/apply-template`,
    data: {
      store_ids: storeIds,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      getStores();
      getRangeTemplates();
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function setRangeTemplateStore({ id, storeId, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: `/store-distance-template/${id}/store/${storeId}/template`,
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      getStores();
      getRangeTemplates();
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function removeRangeTemplateStore({ storeIds, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: `/store-distance-template/store/cancel-template`,
    data: {
      store_ids: storeIds,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      getStores();
      getRangeTemplates();
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function getServiceTemplates({ showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'GET',
    url: '/store-service-templates',
    params: {
      page: 1,
      pagesize: 9999,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      response.data.templates = response.data.templates.map((item) => ({
        ...item,
        tasks: item.tasks
          .map((task) => ({
            ...task,
            start: task.start_time,
            end: task.end_time,
            time: [task.start_time, task.end_time],
          }))
          .sort(
            (taskA, taskB) =>
              Number.parseInt(dayjs(taskA.start, 'HH:mm').format('HHmm'), 10) -
              Number.parseInt(dayjs(taskB.start, 'HH:mm').format('HHmm'), 10),
          ),
        stores: [...item.applied_stores],
      }));
      store.commit('setServiceTemplates', {
        serviceTemplates: response.data.templates,
      });
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function setServiceTemplate({ id, name, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: id ? `/store-service-template/${id}/edit` : '/store-service-template',
    data: {
      id,
      name,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  });
  r.cancel = cancel;
  return r;
}

export function removeServiceTemplates({ ids, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: '/store-service-template/delete',
    data: {
      ids,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      getStores();
      getServiceTemplates();
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function setServiceTemplateTask({ id, start, end, time, service, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: `/store-service-template/${id}/task`,
    data: {
      start_time: time[0] || start,
      end_time: time[1] || end,
      service,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      getStores();
      getServiceTemplates();
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function removeServiceTemplateTasks({ id, taskIds, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: `/store-service-template/${id}/task/delete`,
    data: {
      task_ids: taskIds,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      getStores();
      getServiceTemplates();
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function setServiceTemplateStores({ id, storeIds, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: `/store-service-template/${id}/store/apply-template`,
    data: {
      store_ids: storeIds,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      getStores();
      getServiceTemplates();
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function setServiceTemplateStore({ id, storeId, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: `/store-service-template/${id}/store/${storeId}/template`,
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      getStores();
      getServiceTemplates();
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function removeServiceTemplateStore({ storeIds, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: `/store-service-template/store/cancel-template`,
    data: {
      store_ids: storeIds,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      getStores();
      getServiceTemplates();
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}
