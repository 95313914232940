import Vue from 'vue';
import Vuex from 'vuex';
import { getToken, setToken, removeToken } from '@/utils';

// function loadModules() {
//   const modules = {};
//   const moduleFiles = require.context('./modules', true, /\.js$/);
//   moduleFiles.keys().forEach((key) => {
//     const matched = key.match(/([\w-]+)\./i);
//     if (matched && matched.length > 1) {
//       const moduleName = matched[1];
//       modules[moduleName] = moduleFiles(key).default;
//     }
//   });
//   return modules;
// }

Vue.use(Vuex);

export default new Vuex.Store({
  strict: process.env.NODE_ENV === 'development',
  state: {
    booksets: [],
    stores: [],
    ranges: [],
    onlineStatusTemplates: [],
    rangeTemplates: [],
    serviceTemplates: [],
    pickups: [],
    carousels: [],
    liveUrls: [],
    user: {
      token: getToken(),
      id: -1,
      type: 1,
      name: '',
      storeId: 0,
    },
  },
  getters: {
    bookset(state) {
      return Object.fromEntries(state.booksets.map((cur) => [cur.value, { ...cur }]));
    },
    store: (state) => Object.fromEntries(state.stores.map((cur) => [cur.id, { ...cur }])),
    range: (state) => Object.fromEntries(state.ranges.map((cur) => [cur.id, { ...cur }])),
  },
  mutations: {
    setBooksets(state, { booksets }) {
      state.booksets = booksets;
    },
    setStores(state, { stores }) {
      state.stores = stores;
    },
    setRanges(state, { ranges }) {
      state.ranges = ranges;
    },
    setOnlineStatusTemplates(state, { onlineStatusTemplates }) {
      state.onlineStatusTemplates = onlineStatusTemplates;
    },
    setRangeTemplates(state, { rangeTemplates }) {
      state.rangeTemplates = rangeTemplates;
    },
    setServiceTemplates(state, { serviceTemplates }) {
      state.serviceTemplates = serviceTemplates;
    },
    setPickups(state, { pickups }) {
      state.pickups = pickups;
    },
    setCarousels(state, { carousels }) {
      state.carousels = carousels;
    },
    setLiveUrls(state, { liveUrls }) {
      state.liveUrls = liveUrls;
    },
    setUser(state, { user }) {
      state.user = user;
      if (user.token) {
        setToken(user.token);
      } else {
        removeToken();
      }
    },
    reset(state) {
      state.booksets = [];
      state.stores = [];
      state.ranges = [];
      state.user = {
        token: getToken(),
        id: -1,
        type: 1,
        name: '',
        storeId: 0,
      };
      removeToken();
    },
  },
  // modules: loadModules(),
});
