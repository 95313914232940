import axios from 'axios';
import { isObject, isString } from 'lodash-es';
import request from '@/plugins/request';
import store from '@/store';

const { CancelToken } = axios;

export function getCarousels({ page = 1, size = 9999, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'GET',
    url: '/carousels',
    params: {
      page,
      pagesize: size,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      response.data = response.data.map((carousel) => ({
        ...carousel,
        imageUrl: carousel.image_url,
        sort: carousel.sort_no,
        params: {
          ...carousel.params,
          wxAppId: carousel.params.wx_app_id,
          alipayAppId: carousel.params.alipay_app_id,
          dishId: carousel.params.dishes_id,
          categoryId: carousel.params.category_id,
          extraData: isObject(carousel.params.extraData)
            ? Object.entries(carousel.params.extraData || {})
                .map((item) => item.join('='))
                .join('&')
            : carousel.params.extraData,
        },
      }));
      store.commit('setCarousels', { carousels: response.data });
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function setCarousel({
  id,
  name,
  imageUrl,
  type,
  params,
  status,
  sort,
  showError = true,
} = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: id ? `/carousel/${id}/edit` : `/carousel`,
    data: {
      name,
      image_url: imageUrl,
      type,
      params: {
        ...params,
        wx_app_id: params?.wxAppId,
        alipay_app_id: params?.alipayAppId,
        category_id: params?.dish?.[0],
        dishes_id: params?.dish?.[1],
        extraData: isString(params?.extraData)
          ? Object.fromEntries(params.extraData.split('&').map((item) => item.split('=')))
          : params?.extraData,
      },
      status,
      sort_no: sort,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      getCarousels();
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function removeCarousels({ ids, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: '/carousel/delete',
    data: {
      ids,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      getCarousels();
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function getCarouselMenu({ showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'GET',
    url: '/carousel/dishes',
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      response.data = response.data.map((category) => ({
        ...category,
        value: category.category_id,
        label: category.category_name,
        children: category.dishes.map((dish) => ({
          ...dish,
          value: dish.dishes_id,
          label: dish.dishes_name,
        })),
      }));
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}
