import axios from 'axios';
import request from '@/plugins/request';

const { CancelToken } = axios;

export function getAddresses({ storeId, page = 1, size = 9999, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: '/addresslib/index',
    data: {
      store_id: storeId,
      page,
      pagesize: size,
    },
    params: {
      store_id: storeId,
      page,
      pagesize: size,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      response.data.list = response.data.list.map((item) => ({
        ...item,
        storeId: item.store_id,
        text: item.address,
      }));
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function setAddress({ id, storeId, text, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: id ? '/addresslib/edit' : '/addresslib/add',
    data: {
      id,
      store_id: storeId,
      address: text,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  });
  r.cancel = cancel;
  return r;
}

export function removeAddress({ id, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'GET',
    url: '/addresslib/delete',
    params: {
      id,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  });
  r.cancel = cancel;
  return r;
}

export function getRegions({ storeId, page = 1, size = 9999, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: '/store_district/index',
    data: {
      store_id: storeId,
      page,
      pagesize: size,
    },
    params: {
      store_id: storeId,
      page,
      pagesize: size,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      response.data.list = response.data.list.map((item) => ({
        ...item,
        storeId: item.store_id,
        text: item.district,
      }));
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function setRegion({ id, storeId, text, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: id ? '/store_district/edit' : '/store_district/add',
    data: {
      id,
      store_id: storeId,
      district: text,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  });
  r.cancel = cancel;
  return r;
}

export function removeRegion({ id, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'GET',
    url: '/store_district/delete',
    params: {
      id,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  });
  r.cancel = cancel;
  return r;
}

export function getNegativeAddresses({ storeId, page = 1, size = 9999, showError = true }) {
  let cancel;
  const r = request({
    method: 'GET',
    url: '/addresslib_low/index',
    params: {
      store_id: storeId,
      page,
      pagesize: size,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      response.data.list = response.data.list.map((item) => ({
        ...item,
        storeId: item.store_id,
        text: item.address,
      }));
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function setNegativeAddress({ id, storeId, text, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: id ? '/addresslib_low/edit' : '/addresslib_low/add',
    data: {
      id,
      store_id: storeId,
      address: text,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  });
  r.cancel = cancel;
  return r;
}

export function removeNegativeAddress({ id, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: '/addresslib_low/delete',
    data: {
      id,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  });
  r.cancel = cancel;
  return r;
}
