import pkg from '@/../package.json';

export const baseUrl = process.env.VUE_APP_REQUEST_BASE_URL || 'https://www.ppfix.cn/dcadmin';

export const timeout = 30_000;

export const clientVersion = `${pkg.name}/${pkg.version}`;

export const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'X-Requested-With': 'XMLHttpRequest',
  'X-Device-Type': 'web',
  'X-Client-Version': clientVersion,
};

export const orderStatuses = [
  {
    label: '全部',
    value: 0,
  },
  {
    label: '派单中',
    value: 1,
  },
  {
    label: '已撤销',
    value: 2,
  },
  {
    label: '备餐中',
    value: 3,
  },
  {
    label: '送餐中',
    value: 4,
  },
  {
    label: '已取消',
    value: 5,
  },
  {
    label: '已完结',
    value: 6,
  },
  {
    label: '待支付',
    value: 7,
  },
];

export const orderStatus = Object.fromEntries(orderStatuses.map((cur) => [cur.value, cur]));

export const orderStatusLabels = orderStatuses.map((item) => item.label);

export const orderStatusValues = orderStatuses.map((item) => item.value);

export const orderPayments = [
  {
    label: '全部',
    value: '',
  },
  {
    label: '未选择',
    value: 0,
  },
  {
    label: '餐到付款',
    value: 1,
  },
  {
    label: '电子卡',
    value: 2,
  },
  {
    label: '电子卡 + 餐到付款',
    value: 3,
  },
  {
    label: '微信',
    value: 4,
  },
  {
    label: '电子卡 + 微信',
    value: 6,
  },
  {
    label: '电子券',
    value: 8,
  },
  {
    label: '电子券 + 餐到付款',
    value: 9,
  },
  {
    label: '电子券 + 电子卡',
    value: 10,
  },
  {
    label: '电子券 + 微信',
    value: 12,
  },
  {
    label: '支付宝',
    value: 16,
  },
  {
    label: '电子卡 + 支付宝',
    value: 18,
  },
  {
    label: '电子券 + 支付宝',
    value: 24,
  },
];

export const orderPayment = Object.fromEntries(orderPayments.map((cur) => [cur.value, cur]));

export const orderPaymentLabels = orderPayments.map((item) => item.label);

export const orderPaymentValues = orderPayments.map((item) => item.value);

export const storeRunningStatuses = [
  {
    label: '禁用',
    value: 0,
  },
  {
    label: '启用',
    value: 1,
  },
];

export const storeRunningStatus = Object.fromEntries(
  storeRunningStatuses.map((cur) => [cur.value, cur]),
);

export const storeRunningStatusLabels = storeRunningStatuses.map((item) => item.label);

export const storeRunningStatusValues = storeRunningStatuses.map((item) => item.value);

export const storeOnlineStatuses = [
  {
    label: '上线',
    value: 1,
  },
  {
    label: '下线',
    value: 2,
  },
  {
    label: '繁忙',
    value: 3,
  },
];

export const storeOnlineStatus = Object.fromEntries(
  storeOnlineStatuses.map((cur) => [cur.value, cur]),
);

export const storeOnlineStatusLabels = storeOnlineStatuses.map((item) => item.label);

export const storeOnlineStatusValues = storeOnlineStatuses.map((item) => item.value);

export const storeResponseStatuses = [
  {
    label: '无响应',
    value: 0,
  },
  {
    label: '有响应',
    value: 1,
  },
];

export const storeResponseStatus = Object.fromEntries(
  storeResponseStatuses.map((cur) => [cur.value, cur]),
);

export const storeResponseStatusLabels = storeResponseStatuses.map((item) => item.label);

export const storeResponseStatusValues = storeResponseStatuses.map((item) => item.value);

export const storeServices = [
  {
    label: '提供外卖与自提',
    value: 1,
  },
  {
    label: '仅提供外卖',
    value: 2,
  },
  {
    label: '仅提供自提',
    value: 3,
  },
  {
    label: '不提供外卖与自提',
    value: 4,
  },
];

export const storeService = Object.fromEntries(storeServices.map((cur) => [cur.value, cur]));

export const storeServiceLabels = storeServices.map((item) => item.label);

export const storeServiceValues = storeServices.map((item) => item.value);

export const storeDistanceModes = [
  {
    label: '默认',
    value: 'DEFAULT',
  },
  {
    label: '自定义',
    value: 'CUSTOM',
  },
  {
    label: '模板',
    value: 'TEMPLATE',
  },
];

export const storeDistanceMode = Object.fromEntries(
  storeDistanceModes.map((cur) => [cur.value, cur]),
);

export const storeDistanceModeLabels = storeDistanceModes.map((item) => item.label);

export const storeDistanceModeValues = storeDistanceModes.map((item) => item.value);

export const storeServiceModes = [
  {
    label: '自定义',
    value: 'CUSTOM',
  },
  {
    label: '模板',
    value: 'TEMPLATE',
  },
];

export const storeServiceMode = Object.fromEntries(
  storeServiceModes.map((cur) => [cur.value, cur]),
);

export const storeServiceModeLabels = storeServiceModes.map((item) => item.label);

export const storeServiceModeValues = storeServiceModes.map((item) => item.value);
