import axios from 'axios';
import request from '@/plugins/request';
import store from '@/store';

const { CancelToken } = axios;

export function getPickups({ showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'GET',
    url: '/store-pickup-food-addresses',
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      store.commit('setPickups', { pickups: response.data.list });
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function setPickups({ store_id, addresses, showError = true }) {
  let cancel;
  const r = request({
    method: 'POST',
    url: `/store-pickup-food-addresses/${store_id}/overwrite`,
    data: {
      addresses,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      getPickups();
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}
