import axios from 'axios';
import request from '@/plugins/request';

const { CancelToken } = axios;

export function getSingleLink({ storeId, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'GET',
    url: `/one-store/link/${storeId}`,
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  });
  r.cancel = cancel;
  return r;
}

export function getSingleLinks({ showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'GET',
    url: `/one-store/links`,
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  });
  r.cancel = cancel;
  return r;
}

export function getSingleQRCode({ storeId, withStoreName = false, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'GET',
    url: `/one-store/qrcode/${storeId}`,
    params: {
      print_store_name: withStoreName ? 1 : 0,
    },
    responseType: 'blob',
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  });
  r.cancel = cancel;
  return r;
}

export function getSingleQRCodes({ withStoreName = false, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'GET',
    url: `/one-store/qrcodes`,
    params: {
      print_store_name: withStoreName ? 1 : 0,
    },
    responseType: 'blob',
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  });
  r.cancel = cancel;
  return r;
}
