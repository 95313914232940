import axios from 'axios';
import request from '@/plugins/request';
import store from '@/store';

const { CancelToken } = axios;

export function getLiveUrls({ page = 1, size = 9999, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'GET',
    url: '/store-lives',
    params: {
      page,
      pagesize: size,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      response.data.list = response.data.list.map((item) => ({
        ...item,
        storeId: item.store_id,
      }));
      store.commit('setLiveUrls', { liveUrls: response.data.list });
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function setLiveUrl({ storeId, status, link, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: `/store-live/${storeId}`,
    data: {
      status,
      link,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      getLiveUrls();
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}

export function resetLiveUrls({ storeIds, showError = true } = {}) {
  let cancel;
  const r = request({
    method: 'POST',
    url: '/store-live/reset',
    data: {
      store_ids: storeIds,
    },
    cancelToken: new CancelToken((c) => {
      cancel = c;
    }),
    showError,
  }).then((response) => {
    if (response.success) {
      getLiveUrls();
    }
    return response;
  });
  r.cancel = cancel;
  return r;
}
