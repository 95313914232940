import { Notification } from 'element-ui';

export const showNotification = ({
  title = '成功',
  message = '操作成功，相关数据将自动更新。',
  type = 'success',
} = {}) => {
  Notification({
    title,
    message,
    type,
  });
};
